








import { Component, Vue } from "vue-property-decorator";
import ListadoAdopcion from "@/components/Adopciones/ListadoAdopcion.vue";

@Component({
  components: {
    ListadoAdopcion
  }
})
export default class ListadoAdopcionView extends Vue {}
