




































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {},
})
export default class ListadoAdopcion extends Vue {
  private nombreWeb: any = "";
  private mascotas: Array<any> = [];
  private currentPage = 1;
  private total = 0;
  private paginaCargada = false;
  private categorias: Array<any> = [];
  private id_categoria = "";

  @Watch("currentPage")
  private currentPageWatch(newValue: any) {
    if (newValue >= 0 && newValue <= Math.ceil(this.total / 12)) {
      this.getInfoMascotasCategoria();
    }
  }

  @Watch("id_categoria")
  private selectCat(newValue: any) {
    this.getInfoMascotasCategoria();
  }

  private mounted() {
    this.getInfoMascotasCategoria();
  }

  public enviarEmail(item: any) {
    const subject = "Consulta sobre Mascota";
    const body = `Hola,Estoy interesado(a) en la siguiente mascota:
    Nombre: ${item.nombre}
    Página: https://www.quilpue.cl/adopcion/${item.id}/${item.nombre_web}
    Por favor, envíeme más detalles.
    Gracias,
    `;

    const mailtoLink = `mailto:correoprueba.com?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    window.location.href = mailtoLink;
  }

  private llamar() {
    window.open("tel:+56999999999");
  }

  private mensaje(item: any) {
    const body = `Hola, Estoy interesado(a) en la siguiente mascota:
  Nombre: ${item.nombre}
  Página: https://www.quilpue.cl/adopcion/${item.id}/${item.nombre_web}
  Por favor, envíeme más detalles.
  Gracias,`;

    const encodedMessage = encodeURIComponent(body);
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    if (isMobile) {
      window.open(`https://wa.me/569999999999?text=${encodedMessage}`);
    } else {
      window.open(
        `https://web.whatsapp.com/send?phone=569999999999&text=${encodedMessage}`
      );
    }
  }

  private filtrarCategoria(id) {
    if (id == this.id_categoria) {
      this.borrarFiltro();
    } else if (id != this.id_categoria) {
      this.currentPage = 1;
      this.id_categoria = id;
    } else {
      this.id_categoria = id;
    }
  }

  private getInfoMascotasCategoria() {
    this.$q.loading.show({
      delay: 100, // ms
    });
    // Inicializar path vacío
    var path = "";

    // Agregar id_categoria si es q está presente
    if (this.id_categoria && this.id_categoria.length > 0) {
      path += "?id_categoria=" + this.id_categoria;
    }

    // Agregar el parámetro de la página
    if (path === "") {
      path += "?pagina=" + this.currentPage;
    } else {
      path += "&pagina=" + this.currentPage;
    }

    this.$axios
      .get("adopciones/categoria" + path)
      .then((res: any) => {
        const base = process.env.VUE_APP_BASE_URL;
        this.mascotas = res.data.mascotas;
        this.categorias = res.data.categorias;

        // gatos= 96 perros 95
        this.mascotas.forEach((mascota) => {
          mascota.foto = mascota.foto
            ? base + mascota.foto
            : mascota.id_categoria == 96
            ? require("@/assets/gato.png")
            : require("@/assets/perro.png");

          mascota.banner = mascota.banner
            ? base + mascota.banner
            : require("@/assets/bg-mascota.webp");
        });

        this.total = res.data.total;
        this.$q.loading.hide();
        this.paginaCargada = true;
      })
      .catch((err: any) => {
        // Manejo del error y ocultar el cargador
        this.$q.loading.hide();
        console.log(err);
      });
  }

  private borrarFiltro() {
    this.id_categoria = "";
    this.currentPage = 1;
  }

  private toLink(item: any, target: any) {
    if (item && item.trim() !== "") {
      if (!item.startsWith("http://") && !item.startsWith("https://")) {
        item = "https://" + item;
      }
      window.open(item, target);
    }
  }

  private irMascotaPerfil(id: any, nombreWeb: any) {
    if (
      this.$router.currentRoute.name == "PublicacionMas" &&
      this.$router.currentRoute.params.nombre == nombreWeb &&
      this.$router.currentRoute.params.id == id
    ) {
      this.$router.go(0);
    } else {
      this.$router
        .push({
          name: "DetalleAdopcion",
          params: { id: id, nombre: nombreWeb },
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
}
